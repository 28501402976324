import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFeatures from '../reducers/features.reducer';
import { FEATURES_KEY } from '../reducers/features.reducer';

export const getFeatureState =
  createFeatureSelector<fromFeatures.State>(FEATURES_KEY);

export const getFeatures = createSelector(
  getFeatureState,
  fromFeatures.getFeatures,
);

export const selectFeatureByName = (name: string) =>
  createSelector(getFeatures, (features) =>
    features?.find((feature) => feature === name),
  );
