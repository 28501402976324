import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasFeatureDirective } from '@fc-shared/directives/has-feature/has-feature.directive';

@NgModule({
  declarations: [HasFeatureDirective],
  imports: [CommonModule],
  exports: [HasFeatureDirective],
})
export class HasFeatureModule {}
