import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getFeatures } from '@fc-store/selectors/features.selectors';

import { State } from '../../../map/store';

@Directive({
  selector: '[fcHasFeature]',
})
export class HasFeatureDirective implements OnInit, OnDestroy {
  @Input() fcHasFeature: string;
  isVisible = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store: Store<State>,
  ) {}

  ngOnInit(): void {
    this.subscription = this.store
      .pipe(select(getFeatures))
      .subscribe((features) => {
        if (!features) {
          this.viewContainerRef.clear();
        }
        if (features?.includes(this.fcHasFeature)) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
